
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_config.subsidy_rate') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('globalTrans.fiscal_year')"
                          label-for="fiscal_year_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                    <b-col xs="12" sm="12" md="4">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_config.subsidy_rate_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(percentage)="data">
                                            {{ $n(data.item.percentage) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(rate_type)="data">
                                          {{ getSubsidyType(data.item.rate_type) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyRateList, subsidyRateStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
      formTitle () {
        return (this.editItemId === 0) ? this.$t('dae_config.subsidy_rate') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_config.subsidy_rate') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_config.sub_rate_type'), class: 'text-center' },
          { label: this.$t('dae_config.percentage'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'rate_type' },
          { key: 'percentage' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'rate_type' },
          { key: 'percentage' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      fiscalYearList: function () {
          const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          return fiscalyearData.map(item => {
              if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
              } else {
              return { value: item.value, text: item.text_en }
              }
          })
      }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, subsidyRateStatus, item, 'incentive_grant', 'agMaterialTypeList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, subsidyRateList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList

      const listData = data.map(item => {
        const fiscalYearObject = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = {
          fiscal_year: fiscalYearObject !== undefined ? fiscalYearObject.text_en : '',
          fiscal_year_bn: fiscalYearObject !== undefined ? fiscalYearObject.text_bn : ''
          }

        return Object.assign({}, item, fiscalYearData)
      })
      return listData
    },
    getSubsidyType (rateType) {
      if (rateType === 1) {
        return this.$i18n.locale === 'en' ? 'Haor' : 'হাওর'
      } else if (rateType === 2) {
        return this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল'
      }
    }
  }
}
</script>
