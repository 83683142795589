<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Fiscal year" vid="fiscal_year_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="fiscal_year_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="subsidy.fiscal_year_id"
                                                            :options="fiscalYearList"
                                                            id="fiscal_year_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Land Type" vid="rate_type" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="rate_type"
                                                            slot-scope="{ valid, errors }">
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.sub_rate_type') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="subsidy.rate_type"
                                                            :options="landTypeList"
                                                            id="rate_type"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Percentage" vid="percentage" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="percentage"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_config.percentage')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="percentage"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            v-model="subsidy.percentage"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                                <div class="row">
                                                    <div class="col-sm-3"></div>
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyRateStore, subsidyRateUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getSubsidyRate()
            this.subsidy = tmp
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            subsidy: {
                fiscal_year_id: 0,
                percentage: '',
                rate_type: 0
            }
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        fiscalYearList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        landTypeList: function () {
            const list = [
                    { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
                    { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
                ]
                return list
        }
    },
    methods: {
        getSubsidyRate () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${subsidyRateUpdate}/${this.id}`, this.subsidy)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subsidyRateStore, this.subsidy)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
